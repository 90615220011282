import { Layout, Settings, Sliders, Users, UserPlus } from 'react-feather';

const pagesSection = [
  {
    href: '/salesperson/dashboard',
    title: 'Dashboard',
    icon: Sliders,
    children: [
      {
        href: '/salesperson/dashboard/default',
        title: 'Default',
      },
      {
        href: '/salesperson/dashboard/ranking',
        title: 'Ranking',
      },
    ],
  },
  {
    href: 'sites',
    title: 'Sidor',
    icon: Layout,
  },
  {
    href: 'client',
    title: 'Kunder',
    icon: Users,
  },
  {
    href: 'kundföretag',
    title: 'Kundföretag',
    icon: Users,
  },
  {
    href: 'leads',
    title: 'Kundförfrågningar',
    icon: UserPlus,
  },
  {
    href: 'settings',
    title: 'Inställningar',
    icon: Settings,
  },
  {
    href: 'users',
    title: 'Användare',
    icon: Users,
  },
];

const navItems = [
  {
    title: '',
    pages: pagesSection,
  },
];

export default navItems;
