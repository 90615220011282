import axios from '../utils/axios';

export const getAxcelFile = async () => {
  try {
    return await axios.get('/file/excel', {
      responseType: 'blob',
    });
  } catch (error) {
    return console.log(error);
  }
};
