import { useEffect, useMemo, useRef, useState } from 'react';
import geoData from '../../../swedish_municipalities.json';
import regionGeoData from '../../../swedish_regions.json';
import landscapeGeoData from '../../../swedish_landscape.json';
import useAuth from '../../../hooks/useAuth';
import { MapMode } from '../../../constants/mapMode';

const useMapPage = ({ data }) => {
  const { userMe } = useAuth();
  const geoJsonRef = useRef();
  const [myCities, setMyCities] = useState([]);
  const [myAreas, setMyAreas] = useState([]);
  const [myLandscape, setMyLandscape] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mapState, setMapState] = useState('Cities');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const stockholmPosition = [59.3293, 18.0686];
  const startPosition = useMemo(() => {
    if (mapState === MapMode.CITIES) {
      return myCities[0]?.coordinates || stockholmPosition;
    }
    if (mapState === MapMode.AREAS) {
      return myAreas[0]?.coordinates || stockholmPosition;
    }
    if (mapState === MapMode.LANDSCAPE) {
      return myLandscape[0]?.coordinates || stockholmPosition;
    }
    return stockholmPosition;
  }, [mapState, myCities, myAreas, myLandscape]);

  const getColor = (name) => {
    let currentData;

    switch (mapState) {
      case MapMode.CITIES:
        currentData = myCities;
        break;
      case MapMode.AREAS:
        currentData = myAreas;
        break;
      case MapMode.LANDSCAPE:
        currentData = myLandscape;
        break;
      default:
        return '#ffffff';
    }

    if (currentData) {
      const cityData = currentData.find((cityObj) => cityObj.city === name);
      if (cityData && cityData.sites.length > 0) {
        const allAvailable = cityData.sites.every((site) => site.isAvailable);
        const noneAvailable = cityData.sites.every((site) => !site.isAvailable);

        if (allAvailable) {
          return '#50B4B2';
        }
        if (noneAvailable) {
          return '#C7468B';
        }
        return '#F36DF7';
      }
    }

    return '#ffffff';
  };

  const style = (feature) => ({
    fillColor: getColor(feature.properties.kom_namn),
    weight: 1,
    opacity: 1,
    color: 'white',
    dashArray: '3',
    fillOpacity: 0.5,
  });

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: (e) => {
        const targetLayer = e.target;
        targetLayer.setStyle({
          weight: 2,
          color: '#666',
          dashArray: '',
          fillOpacity: 0.2,
        });
        targetLayer.bringToFront();
      },
      mouseout: (e) => {
        if (geoJsonRef.current) {
          geoJsonRef.current.resetStyle(e.target);
        }
      },
      click: () => {
        let currentCity;
        if (mapState === MapMode.CITIES) {
          currentCity = myCities.filter((obj) => obj.city === feature.properties.kom_namn);
        }
        if (mapState === MapMode.AREAS) {
          currentCity = myAreas.filter((obj) => obj.city === feature.properties.kom_namn);
        }
        if (mapState === MapMode.LANDSCAPE) {
          currentCity = myLandscape.filter((obj) => obj.city === feature.properties.kom_namn);
        }
        if (currentCity.length) {
          setSelectedFeature({
            properties: feature.properties,
            coordinates: feature.geometry.coordinates,
            city: currentCity,
          });
        } else {
          alert(`You clicked on ${feature.properties.kom_namn}`);
        }
      },
    });
  };

  const handelClick = () => {
    if (mapState === MapMode.CITIES) {
      setMapState(MapMode.AREAS);
    }
    if (mapState === MapMode.AREAS) {
      setMapState(MapMode.LANDSCAPE);
    }
    if (mapState === MapMode.LANDSCAPE) {
      setMapState(MapMode.CITIES);
    }
    setSelectedFeature(null);
  };

  const getMarkersData = () => {
    switch (mapState) {
      case MapMode.AREAS:
        return myAreas;
      case MapMode.CITIES:
        return myCities;
      case MapMode.LANDSCAPE:
        return myLandscape;
      default:
        return [];
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    setSelectedFeature(null);
    const getDomainsList = async () => {
      const initialCitiesMap = {};
      const initialAreasMap = {};
      const initialLandscapeMap = {};

      const processFeature = (obj, currentData, map, key) => {
        const geoFeature = currentData.features.find(
          (feature) => feature.properties.kom_namn === obj[key]?.name,
        );
        if (geoFeature) {
          return {
            ...map,
            [obj[key].name]: {
              city: obj[key].name,
              sites: (map[obj[key].name]?.sites || []).concat({
                domain: obj.domain,
                googleRank: obj.googleRank,
                leadCount: obj.formLeadCount,
                isAvailable: obj.isAvailable,
              }),
              coordinates: geoFeature.properties.geo_point_2d,
            },
          };
        }
        return map;
      };

      let citiesMap = { ...initialCitiesMap };
      let areasMap = { ...initialAreasMap };
      let landscapeMap = { ...initialLandscapeMap };

      data.forEach((obj) => {
        citiesMap = processFeature(obj, geoData, citiesMap, 'city');
        areasMap = processFeature(obj, regionGeoData, areasMap, 'area');
        landscapeMap = processFeature(obj, landscapeGeoData, landscapeMap, 'landscape');
      });

      setMyCities(Object.values(citiesMap));
      setMyAreas(Object.values(areasMap));
      setMyLandscape(Object.values(landscapeMap));
    };
    setIsLoaded(true);
    getDomainsList();
  }, [data, userMe]);

  return {
    myCities,
    myAreas,
    myLandscape,
    startPosition,
    geoData,
    geoJsonRef,
    isLoaded,
    regionGeoData,
    landscapeGeoData,
    mapState,
    MapMode,
    selectedFeature,
    style,
    onEachFeature,
    handelClick,
    setSelectedFeature,
    getMarkersData,
  };
};

export default useMapPage;
