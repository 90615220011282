import { useCallback, useEffect, useState } from 'react';
import { getAllSitesCompany, putOptimization } from '../API/companyApi';
import { debounce } from '../utils/debounce';

const useOptimizationSiteTable = (companyName, comment, optimizationsId, status, changeComment) => {
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [notes, setNotes] = useState('');
  const [stage, setStage] = useState(status);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const getSitesList = async () => {
    const res = await getAllSitesCompany(companyName);
    setSites(res);
    setLoading(false);
  };

  useEffect(() => {
    getSitesList();
    setNotes(comment);
  }, []);

  const debouncedPutOptimization = useCallback(
    debounce((value) => {
      putOptimization({ id: optimizationsId, comment: value || '' });
    }, 3000),
    [],
  );

  const handleInputChange = useCallback(
    (e) => {
      const newNotes = e.target.value;
      changeComment(optimizationsId, newNotes);
      debouncedPutOptimization(newNotes);
      setNotes(newNotes);
    },
    [notes],
  );

  const saveStage = async (e) => {
    try {
      setLoading(true);
      setOpen(false);
      setStage(e.target.value);
      putOptimization({ id: optimizationsId, stage: e.target.value });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };
  return {
    loading,
    sites,
    notes,
    stage,
    open,
    anchorEl,
    setOpen,
    handleInputChange,
    saveStage,
    handleClick,
  };
};

export default useOptimizationSiteTable;
