import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSitesList } from '../API/siteApi';

const useSiteList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sitesList, setSitesList] = useState([]);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [page, setPage] = useState(parseInt(searchParams.get('page'), 10) || 0);
  const [limit, setLimit] = useState(parseInt(searchParams.get('limit'), 10) || 20);
  const [isAvailable, setIsAvailable] = useState(searchParams.get('isAvailable') || 2);
  const [isMapView, setIsMapView] = useState(searchParams.get('view') === 'map');
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const updateSite = async () => {
    const data = await getSitesList(search.toLowerCase(), page, limit, isAvailable, isMapView);
    setCount(data.count);
    setSitesList(data.sites);
    setIsLoading(false);
  };

  useEffect(() => {
    updateSite();
  }, [search, page, limit, isAvailable, isMapView]);

  useEffect(() => {
    setSearch(searchParams.get('search') || '');
    setPage(parseInt(searchParams.get('page'), 10) || 0);
    setLimit(parseInt(searchParams.get('limit'), 10) || 20);
    setIsAvailable(searchParams.get('isAvailable') || 2);
    setIsMapView(searchParams.get('view') === 'map');
  }, [searchParams]);

  const updateURLParams = useCallback(
    (params) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        Object.keys(params).forEach((key) => {
          newParams.set(key, params[key]);
        });
        return newParams;
      });
    },
    [setSearchParams],
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    updateURLParams({ search: value, page: 0 });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    updateURLParams({ page: newPage });
  };

  const handleLimitChange = (e) => {
    const value = e.target.value;
    setLimit(value);
    updateURLParams({ limit: value, page: 0 });
  };

  const handleViewChange = () => {
    setIsLoading(true);
    const newIsMapView = !isMapView;
    setIsMapView(newIsMapView);
    setLimit(newIsMapView ? 5000 : 20);
    updateURLParams({
      view: newIsMapView ? 'map' : 'list',
      limit: newIsMapView ? 5000 : 20,
      page: 0,
    });
  };

  const handleAvailableChange = (event) => {
    const value = event.target.value;
    setIsAvailable(value);
    updateURLParams({ isAvailable: value, page: 0 });
  };

  return {
    sitesList,
    search,
    page,
    count,
    limit,
    isAvailable,
    isMapView,
    isLoading,
    handleSearchChange,
    handlePageChange,
    handleLimitChange,
    handleViewChange,
    handleAvailableChange,
  };
};

export default useSiteList;
