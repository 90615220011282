import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { getOptimizationList } from '../API/companyApi';

const useOptimizationClientList = () => {
  const [companyList, setCompanyList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [expandedId, setExpandedId] = useState(null);
  const [loadingCell, setLoadingCell] = useState(null);

  const isDesktop = useMediaQuery('(min-width:600px)');

  const updateList = async () => {
    setLoadingCell(0);
    const data = await getOptimizationList({
      limit,
      page,
    });
    setCount(data.totalOptimizationsCount);
    setCompanyList(data.optimizations);
    setLoadingCell(null);
  };

  useEffect(() => {
    updateList();
  }, [page, limit]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeLimit = useCallback((e) => {
    setPage(0);
    setLimit(e.target.value);
  }, []);

  const clickHandler = useCallback((id) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  });

  const changeComment = useCallback((id, newComment) => {
    setCompanyList((prevUsersList) =>
      prevUsersList.map((user) => (user.id === id ? { ...user, comment: newComment } : user)),
    );
  }, []);

  return {
    companyList,
    page,
    count,
    limit,
    expandedId,
    loadingCell,
    isDesktop,
    setCompanyList,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
    changeComment,
  };
};

export default useOptimizationClientList;
