import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Line } from 'react-chartjs-2';

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Autocomplete,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { spacing } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { getSiteStatistics, getSitesList } from '../API/siteApi';
import { getLastSevenDaysRange, getLastThirtyDaysRange } from '../utils/timeJs';
import Loader from './Loader';
import useAuth from '../hooks/useAuth';
import { GlobalContext } from '../contexts/GlobalContext';

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

const PagesChart = ({ theme }) => {
  const { sortingMySites } = useContext(GlobalContext);
  const { userMe } = useAuth();
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState();
  const [period, setPeriod] = useState('month');
  const [currentSite, setCurrentSite] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchSiteList, setSearchSiteList] = useState(null);

  useEffect(() => {
    try {
      if (sortingMySites) {
        setSearchSiteList(sortingMySites);
        setCurrentSite({
          ...sortingMySites[0],
        });

        setSearch(sortingMySites[0]?.domain);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [sortingMySites]);

  useEffect(() => {
    const getSearchSite = async () => {
      if (userMe === 'admin' || userMe === 'user') {
        const response = await getSitesList(search || null, 0, 3000);
        setSearchSiteList(response.sites);
      }
    };
    getSearchSite();
  }, [search]);

  useEffect(() => {
    const getStats = async () => {
      if (currentSite?.nightwatchUrlId) {
        try {
          let statisticData;
          if (period === 'week') {
            statisticData = getLastSevenDaysRange();
          }
          if (period === 'month') {
            statisticData = getLastThirtyDaysRange();
          }
          const statistic = await getSiteStatistics(
            currentSite.nightwatchUrlId,
            statisticData.from,
            statisticData.to,
          );
          const interval = [];
          const intervalValue = [];
          statistic.data[0].url_series[0].series.forEach((day) => {
            interval.push(day[0]);
            intervalValue.push(day[1]);
          });
          setSeries({ allDay: [...interval], allValue: [...intervalValue] });
        } catch (err) {
          console.error(err);
        }
      }
    };
    getStats();
  }, [currentSite, period]);

  const data = {
    labels: series?.allDay,
    datasets: [
      {
        borderColor: theme.palette.secondary.main,
        tension: 0.4,
        data: series?.allValue,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
      },

      y: {
        reverse: true,
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff',
        },
        ticks: {
          callback: (value) => (Number.isInteger(Number(value)) ? value : ''),
        },
        title: {
          display: true,
          text: 'Ranking',
        },
      },
    },
  };

  const clearInput = () => {
    setCurrentSite({ domain: '' });
    setSearch('');
  };

  if (loading) {
    return (
      <div style={{ width: '100%', height: '300px' }}>
        <Loader />;
      </div>
    );
  }
  if (!sortingMySites?.length) {
    return <h2>You don`t have sites</h2>;
  }
  return (
    <Card>
      <CardHeader
        action={
          userMe === 'client' ? (
            <FormControl sx={{ m: 1, width: 220, marginTop: '12px' }}>
              <Select
                size="small"
                defaultValue={sortingMySites[0]}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setCurrentSite(e.target.value);
                }}>
                {searchSiteList.map((site) => (
                  <MenuItem key={site.id} value={site}>
                    {site.domain}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div style={{ position: 'relative' }}>
              <CloseIcon
                onClick={clearInput}
                sx={{
                  position: 'absolute',
                  right: '40px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  zIndex: 1,
                }}
              />
              <Autocomplete
                value={currentSite}
                disableClearable
                isOptionEqualToValue={() => true}
                getOptionLabel={(option) => option.domain}
                options={searchSiteList}
                sx={{
                  width: 250,
                  margin: '12px',
                  marginLeft: 0,
                }}
                size="small"
                onChange={(event, newValue) => {
                  setCurrentSite({
                    ...newValue,
                  });
                  setSearch(newValue?.domain);
                }}
                renderInput={(params) => (
                  <TextField
                    onChange={(event) => setSearch(event.target.value)}
                    {...params}
                    label="Current page"
                  />
                )}
              />
            </div>
          )
        }
        title={
          <>
            <Button onClick={() => setPeriod('week')}>Week</Button>
            <Button onClick={() => setPeriod('month')}>Month</Button>
          </>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <ChartWrapper style={{ height: '300px' }}>
          {series && <Line data={data} options={options} />}
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};
export default withTheme(PagesChart);
