import React from 'react';
import { StyledEngineProvider } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './i18n';
import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';
import { store } from './redux/store';

import { AuthProvider } from './contexts/JWTContext';
import { GlobalContextProvider } from './contexts/GlobalContext';

const App = () => {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  const generateClassName = createGenerateClassName({
    disableGlobal: true,
    seed: 'mui-jss',
  });

  return (
    <HelmetProvider>
      <Helmet defaultTitle="Addictivemedia" />

      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <StylesProvider generateClassName={generateClassName}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                <AuthProvider>
                  <GlobalContextProvider>{content}</GlobalContextProvider>
                </AuthProvider>
              </MuiThemeProvider>
            </LocalizationProvider>
          </StylesProvider>
        </StyledEngineProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
