import React, { useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { Button } from '@mui/material';
import Loader from '../../Loader';
import logo from '../../../vendor/blue_logo.png';
import LinkWithGoogleRank from './LinkWithGoogleRank';
import useMapPage from './useMapPage';

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const myBigCustomIcon = new L.Icon({
  iconUrl: logo,
  iconSize: [45, 45],
  iconAnchor: [22, 45],
});

const myCustomIcon = new L.Icon({
  iconUrl: logo,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const MapPage = ({ data, isPin, isAllCountryView }) => {
  const {
    startPosition,
    myCities,
    myAreas,
    myLandscape,
    geoData,
    geoJsonRef,
    isLoaded,
    regionGeoData,
    landscapeGeoData,
    mapState,
    MapMode,
    selectedFeature,
    getMarkersData,
    style,
    onEachFeature,
    handelClick,
    setSelectedFeature,
  } = useMapPage({ data });

  const renderGeoJSON = useMemo(() => {
    if (!isLoaded) {
      return null;
    }
    switch (mapState) {
      case MapMode.CITIES:
        return (
          <GeoJSON
            key={Date.now()}
            data={geoData}
            style={style}
            onEachFeature={onEachFeature}
            ref={geoJsonRef}
          />
        );
      case MapMode.AREAS:
        return (
          <GeoJSON
            key={Date.now()}
            data={regionGeoData}
            style={style}
            onEachFeature={onEachFeature}
            ref={geoJsonRef}
          />
        );
      case MapMode.LANDSCAPE:
        return (
          <GeoJSON
            key={Date.now()}
            data={landscapeGeoData}
            style={style}
            onEachFeature={onEachFeature}
            ref={geoJsonRef}
          />
        );
      default:
        return null;
    }
  }, [mapState, myCities, myAreas, myLandscape]);

  const markerIconSize = useMemo(() => {
    if (mapState === MapMode.CITIES) {
      return myCustomIcon;
    }
    return myBigCustomIcon;
  }, [mapState]);

  if (!isLoaded) {
    return <Loader />;
  }
  return (
    <div style={{ height: 'calc(100vh - 218px)', width: '100%', position: 'relative' }}>
      <Button
        variant="contained"
        style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 500 }}
        onClick={handelClick}>
        {mapState}
      </Button>
      {startPosition && (
        <MapContainer
          center={isAllCountryView ? [63, 15] : startPosition}
          zoom={isAllCountryView ? 5 : 9}
          style={{ height: '100%', width: '100%' }}
          keyboard={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {isPin &&
            getMarkersData().map((cityObj) => (
              <Marker key={cityObj.city} icon={markerIconSize} position={cityObj.coordinates}>
                <Popup>
                  <h3 style={{ margin: 0 }}>{cityObj.city}:</h3>
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {cityObj.sites.map((site, index) => (
                      <LinkWithGoogleRank
                        key={site.domain}
                        site={site}
                        withBorder={index < cityObj.sites.length - 1}
                      />
                    ))}
                  </div>
                </Popup>
              </Marker>
            ))}
          {renderGeoJSON}
          {selectedFeature && (
            <Popup
              position={[
                selectedFeature.properties.geo_point_2d[0],
                selectedFeature.properties.geo_point_2d[1],
              ]}
              onClose={() => setSelectedFeature(null)}>
              {selectedFeature.city.length && (
                <>
                  <h3 style={{ margin: 0 }}>{selectedFeature.properties.kom_namn}:</h3>
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {selectedFeature.city[0].sites?.map((site, index) => (
                      <LinkWithGoogleRank
                        key={site.domain}
                        site={site}
                        withBorder={index < selectedFeature.city[0].sites.length - 1}
                      />
                    ))}
                  </div>
                </>
              )}
            </Popup>
          )}
        </MapContainer>
      )}
    </div>
  );
};

export default MapPage;
