import { LEAD_SOURCES } from '../constants/leadConstants';

export const leadsParser = (leads) => {
  const allLeads = [];
  leads?.map((leadData) => {
    const lead = leadData.dataValues || leadData;
    let data;
    try {
      data = JSON.parse(lead.data);
    } catch (error) {
      data = lead.data;
    }

    let leadObj = {
      id: lead.id,
      createdAt: lead.createdAt,
      source: lead.source,
      domain: lead.site?.domain || leadData.domain,
      isNew: lead.isNew,
      leadFiles: lead.leads_files,
    };
    if (lead.source === LEAD_SOURCES.CUSTOM) {
      leadObj = {
        ...leadObj,
        from: data.from,
        content: data.content,
      };
    }
    if (lead.source === LEAD_SOURCES.NIMBATA || lead.source === LEAD_SOURCES.FORM) {
      leadObj = {
        ...leadObj,
        data,
      };
    }
    return allLeads.push({ ...leadObj });
  });

  return allLeads;
};
