import {
  Button,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  TextField,
  Menu,
  TableHead,
} from '@mui/material';
import styled from '@emotion/styled';
import Loader from './Loader';

import { OPTIMIZATION_STAGE } from '../constants/optimizationsStage';
import useOptimizationSiteTable from '../hooks/useOptimizationSitesTable';

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;

const OptimizationSitesTable = ({
  companyName,
  comment,
  optimizationsId,
  status,
  changeComment,
}) => {
  const {
    loading,
    sites,
    notes,
    stage,
    open,
    anchorEl,
    setOpen,
    handleInputChange,
    saveStage,
    handleClick,
  } = useOptimizationSiteTable(companyName, comment, optimizationsId, status, changeComment);

  if (loading || !sites) {
    return null;
  }

  return (
    <Paper>
      <Table style={{ border: '1px solid #e0e0e0', margin: '15px 0' }}>
        <TableHead>
          <TableRow>
            <TableCell>Sidor</TableCell>
            <TableCell sx={{ textAlign: 'end' }}>Ranking</TableCell>
            <TableCell sx={{ textAlign: 'end' }}>Total leads</TableCell>
            <TableCell sx={{ textAlign: 'end' }}>Leads 30 dagar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sites.length > 0 &&
            sites.map((site) => (
              <TableRow key={site.id}>
                <TableCell>{site.domain}</TableCell>
                <TableCell sx={{ textAlign: 'end' }}>{site.googleRank}</TableCell>
                <TableCell sx={{ textAlign: 'end' }}>{site.totalLeads || 0}</TableCell>
                <TableCell sx={{ textAlign: 'end' }}>{site.lastMonthLeads || 0}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <>
        <TextField
          multiline
          sx={{
            width: '100%',
            '& .MuiInputBase-multiline': {
              padding: '8px',
            },
            '& .MuiInputBase-input': {
              fontSize: '12px',
            },
          }}
          minRows={3}
          maxRows={8}
          size="sm"
          variant="outlined"
          value={notes}
          name="notes"
          onChange={handleInputChange}
        />
        {loading ? (
          <Loader />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ color: stage === 0 ? 'white' : 'red' }}>
              In optimization list, stage{' '}
              {Object.keys(OPTIMIZATION_STAGE).find(
                (key) => OPTIMIZATION_STAGE[key] === stage && key,
              )}
            </span>

            <Button
              disabled={loading}
              onClick={handleClick}
              sx={{ margin: '15px 0' }}
              size="small"
              variant="contained">
              Change stage
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => setOpen(false)}
              onChange={saveStage}>
              <MenuItem
                sx={{
                  background: stage === OPTIMIZATION_STAGE.BESTÄLLNING ? '#EDF7F9' : '',
                }}
                onClick={saveStage}
                value={OPTIMIZATION_STAGE.BESTÄLLNING}>
                Beställning
              </MenuItem>
              <MenuItem
                sx={{
                  background: stage === OPTIMIZATION_STAGE.PRODUCTION ? '#EDF7F9' : '',
                }}
                onClick={saveStage}
                value={OPTIMIZATION_STAGE.PRODUCTION}>
                Production
              </MenuItem>
              <MenuItem
                sx={{
                  background: stage === OPTIMIZATION_STAGE.LIVE ? '#EDF7F9' : '',
                }}
                onClick={saveStage}
                value={OPTIMIZATION_STAGE.LIVE}>
                Live
              </MenuItem>
            </Menu>
          </div>
        )}
      </>
    </Paper>
  );
};

export default OptimizationSitesTable;
