import React, { Fragment } from 'react';
import styled from '@emotion/styled';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Button,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Collapse,
} from '@mui/material';
import { spacing } from '@mui/system';

import DatePopup from '../DatePopup';
import SortingTableCell from '../SortingTableCell';
import { LEADS_EXPECTATION_NAMES } from '../../constants/leadsExpectationNames';
import SubscriptionsTable from '../SubscriptionsTable';
import useClientCompanyList from '../../hooks/useClientCompanyList';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;

const ClientsCompaniesList = () => {
  const {
    clientCompaniesList,
    search,
    page,
    count,
    limit,
    openDateFilter,
    dateFilter,
    currentSalespersonId,
    expandedId,
    type,
    loadingCell,
    sort,
    reverseSort,
    isDesktop,
    salespersons,
    setCurrentSalespersonId,
    setOpenDateFilter,
    changeSort,
    changeDateFilter,
    searchInputHandler,
    typeHandler,
    getSalespersonNamesString,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
  } = useClientCompanyList();

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: '24px',
          flexWrap: 'wrap',
        }}>
        <Typography
          sx={{
            width: '100%',
            fontWeight: 500,
            '@media (max-width: 600px)': {
              fontSize: '30px',
            },
          }}
          variant="h2">
          Kundföretag
        </Typography>
      </div>
      <Card
        mb={6}
        sx={{
          maxWidth: '100%',
          '@media (min-width: 960px)': {
            maxWidth: 'calc(100vw - 205px)',
          },
          '@media (min-width: 1280px)': {
            maxWidth: 'calc(100vw - 242px)',
          },
          border: '1px solid',
          borderColor: 'grey.300',
        }}>
        <div
          style={{
            margin: '16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box sx={{ display: 'flex', gap: '16px 0', flexWrap: 'wrap' }}>
            <TextField
              sx={{ marginBottom: 0, marginRight: '15px' }}
              id="standard-basic"
              label="Sök företag"
              variant="outlined"
              onChange={searchInputHandler}
              value={search}
              size="small"
            />
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <InputLabel>Client status</InputLabel>
              <Select size="small" value={type} onChange={typeHandler} label="Client status">
                <MenuItem
                  value="active"
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                  Active
                  <span> {count.isActive} </span>
                </MenuItem>
                <MenuItem
                  value="new"
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                  New
                  <span> {count.isNew} </span>
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <InputLabel id="salesperson-label">Kunder</InputLabel>
              <Select
                disabled={type === 'new'}
                size="small"
                labelId="salesperson-label"
                value={currentSalespersonId}
                onChange={(e) => setCurrentSalespersonId(e.target.value)}
                label="Kunder">
                <MenuItem value={0}>All Kunder</MenuItem>
                {salespersons?.map((sales) => (
                  <MenuItem key={sales.id} value={sales.id}>
                    {sales.firstName} {sales.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <Button
                disabled={type === 'new'}
                variant="outlined"
                onClick={() => setOpenDateFilter(true)}
                sx={{
                  flexGrow: 1,
                  borderColor: '#BBBBBB',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 400,
                  '&:hover': {
                    borderColor: '#575757',
                    backgroundColor: '#fff',
                  },
                }}>
                Filtrera på datum
              </Button>
              <DatePopup
                open={openDateFilter}
                onClose={() => setOpenDateFilter(false)}
                data={dateFilter}
                setDate={(data) => changeDateFilter(data)}>
                <MenuItem value="off">Inget filter</MenuItem>
                <MenuItem value="agreement_start_date">Live datum</MenuItem>
                <MenuItem value="notice_date">Uppsägningsdatum</MenuItem>
                <MenuItem value="agreement_end_date">Slutdatum</MenuItem>
                <MenuItem value="renewal_date">Förnyelsedatum</MenuItem>
              </DatePopup>
            </FormControl>
          </Box>
          <Divider my={6} sx={{ margin: '12px 0 0' }} />
        </div>
        {loadingCell === 0 ? (
          <Box sx={{ width: '22px', height: '50px', margin: '50px auto 0' }}>
            <CircularProgress sx={{ width: '100%', height: '100%' }} />
          </Box>
        ) : (
          <>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontWeight: 400,
                        position: isDesktop ? 'sticky' : 'static',
                        left: 0,
                        zIndex: 1,
                        background: 'white',
                      }}>
                      Företag
                    </TableCell>

                    <SortingTableCell
                      title="Live datum"
                      fieldName="agreement_start_date"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Uppsägningsdatum"
                      fieldName="notice_date"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Slutdatum"
                      fieldName="agreement_end_date"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Förnyelsedatum"
                      fieldName="renewal_date"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Revenue"
                      fieldName="total_revenue"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Total leads"
                      fieldName="total_leads"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Förväntan"
                      fieldName="company_leads_expectation_id"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Leads 30 dagar"
                      fieldName="total_leads_last_30_days"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <SortingTableCell
                      title="Antal sidor"
                      fieldName="total_sites"
                      sort={sort}
                      reverseSort={reverseSort}
                      onClick={changeSort}
                    />
                    <TableCell
                      sx={{ whiteSpace: 'nowrap', width: '200px', fontWeight: 400 }}
                      align="right">
                      Säljare
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientCompaniesList?.map((row) => (
                    <Fragment key={row.id}>
                      <TableRow>
                        <TableCell
                          onClick={() => clickHandler(row.id)}
                          sx={{
                            whiteSpace: 'nowrap',
                            fontWeight: 700,
                            position: isDesktop ? 'sticky' : 'static',
                            left: 0,
                            background: 'white',
                            zIndex: 1,
                            borderBottom: expandedId === row.id && 0,
                            cursor: 'pointer',
                          }}>
                          {row.name || ''}{' '}
                          {row.subscriptions?.length > 1 && (
                            <sup style={{ color: 'red' }}>{row.subscriptions.length}</sup>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'end',
                            borderBottom: expandedId === row.id && 0,
                          }}>
                          {row.agreement_start_date?.slice(0, 10) || ''}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'end',
                            borderBottom: expandedId === row.id && 0,
                          }}>
                          {row.notice_date?.slice(0, 10) || ''}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'end',
                            borderBottom: expandedId === row.id && 0,
                          }}>
                          {row.agreement_end_date?.slice(0, 10) || ''}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'end',
                            borderBottom: expandedId === row.id && 0,
                          }}>
                          {row.renewal_date?.slice(0, 10) || ''}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: 'end', borderBottom: expandedId === row.id && 0 }}>
                          {row.total_revenue?.toFixed(0) || ''}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: 'end', borderBottom: expandedId === row.id && 0 }}>
                          {row.total_leads || 0}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'end',
                            whiteSpace: 'nowrap',
                            borderBottom: expandedId === row.id && 0,
                          }}>
                          {row.company_leads_expectation_id
                            ? LEADS_EXPECTATION_NAMES[row.company_leads_expectation_id]
                            : ''}
                        </TableCell>

                        <TableCell
                          sx={{ textAlign: 'end', borderBottom: expandedId === row.id && 0 }}>
                          {row.total_leads_last_30_days}
                        </TableCell>

                        <TableCell
                          sx={{ textAlign: 'end', borderBottom: expandedId === row.id && 0 }}>
                          {row.total_sites || 0}
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'end',
                            borderBottom: expandedId === row.id && 0,
                          }}>
                          {row.subscriptions[0]?.salesperson_id
                            ? getSalespersonNamesString(row.subscriptions[0].salesperson_id)
                            : ''}
                        </TableCell>
                      </TableRow>
                      {row.subscriptions?.length > 0 && (
                        <TableRow
                          sx={{
                            borderBottom: expandedId === row.id && '1px solid #e0e0e0',
                            backgroundColor: '#fff',
                          }}>
                          <TableCell
                            style={{
                              position: isDesktop ? 'sticky' : 'static',
                              left: 0,
                              paddingBottom: 0,
                              paddingTop: 0,
                              borderTop: 'white',

                              borderWidth: 0,
                            }}
                            colSpan={5}>
                            <Collapse in={expandedId === row.id} timeout="auto" unmountOnExit>
                              <SubscriptionsTable
                                subscriptions={row.subscriptions}
                                companyName={row.name}
                                getSalespersonNamesString={getSalespersonNamesString}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
            {count > limit && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeLimit}
                />
              </div>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default ClientsCompaniesList;
