import { createContext, useEffect, useMemo, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { getMySites, getSitesList } from '../API/siteApi';

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const { userMe } = useAuth();
  const [sortingMySites, setSortingMySites] = useState();

  useEffect(() => {
    const getTopSite = async () => {
      try {
        let sites;
        if (userMe === 'admin' || userMe === 'user') {
          const response = await getSitesList(null, 0, 5000);
          sites = response.sites;
        } else {
          const response = await getMySites(null, 0, 5000, userMe);
          sites = response.sites.sites;
        }

        const allSites = sites.sort((a, b) => {
          if (a.googleRank < 1 && b.googleRank !== 0) {
            return 1;
          }
          if (a.googleRank !== 0 && b.googleRank < 1) {
            return -1;
          }
          return a.googleRank - b.googleRank;
        });
        setSortingMySites(allSites);
      } catch (err) {
        console.error(err);
      }
    };
    if (userMe) {
      getTopSite();
    }
  }, [userMe]);

  const contextValue = useMemo(() => ({ sortingMySites, setSortingMySites }), [sortingMySites]);

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};
