import axios from '../utils/axios';

export const getUsersList = async ({
  search,
  page,
  limit,
  role,
  isInvited,
  salespersonId,
  statisticData,
  withSites,
  sort,
  reverseSort,
  countActions,
  getLastLogin,
  dateFilter,
  getCompanyLeadsExpectation,
  withBadOptimization,
  getCompanies,
}) => {
  const decodedSearch = search?.replaceAll('&', '%26');
  let query = '';
  if (search?.length > 0) {
    query += `search=${decodedSearch}`;
  }
  if (page !== 0) {
    query += `&page=${page}`;
  }
  if (limit !== 20) {
    query += `&limit=${limit}`;
  }
  if (role) {
    query += query.length ? `&role=${role}` : `role=${role}`;
  }
  if (isInvited) {
    query += query.length ? `&isInvited=${isInvited}` : `isInvited=${isInvited}`;
  }
  if (salespersonId > 0) {
    query += query.length ? `&salespersonId=${salespersonId}` : `salespersonId=${salespersonId}`;
  }

  if (statisticData) {
    query += '&statisticData=true';
  }

  if (sort) {
    query += query.length ? `&sort=${sort}` : `sort=${sort}`;
  }
  if (reverseSort) {
    query += query.length ? `&reverseSort=${reverseSort}` : `reverseSort=${reverseSort}`;
  }

  if (withSites !== undefined) {
    query += query.length ? `&withSites=${withSites}` : `withSites=${withSites}`;
  }
  if (countActions !== undefined) {
    query += query.length ? `&countActions=${countActions}` : `countActions=${countActions}`;
  }
  if (getLastLogin !== undefined) {
    query += query.length ? `&getLastLogin=${getLastLogin}` : `getLastLogin=${getLastLogin}`;
  }
  if (dateFilter && dateFilter.type && dateFilter.type !== 'off') {
    query += query.length
      ? `&dateFilter=${dateFilter.type}&from=${dateFilter.from}&to=${dateFilter.to}`
      : `dateFilter=${dateFilter.type}&from=${dateFilter.from}&to=${dateFilter.to}`;
  }
  if (getCompanyLeadsExpectation) {
    query += query.length ? '&getCompanyLeadsExpectation=true' : 'getCompanyLeadsExpectation=true';
  }
  if (withBadOptimization) {
    query += query.length ? '&withBadOptimization=true' : 'withBadOptimization=true';
  }
  if (getCompanies) {
    query += query.length ? '&getCompanies=true' : 'getCompanies=true';
  }
  const { data } = await axios.get(`/api/my-pages/user?${query}`);
  return data;
};

export const getUserById = async (id) => {
  const { data } = await axios.get(`/api/my-pages/user/${id}`);
  return data;
};

export const saveUserAction = async (action) => {
  try {
    await axios.get(`/api/my-pages/user/action?action=${action}`);
  } catch (err) {
    console.log(err);
  }
};

export const createUser = async (data) => {
  const response = await axios.post('/api/my-pages/user', { ...data });
  return response;
};

export const changeUserData = async (data, userId) => {
  const response = await axios.put(`/api/my-pages/user?userId=${userId}`, data);
  return response;
};

export const getDashboard = async () => {
  const { data } = await axios.get('/api/my-pages/dashboard/user');
  return data;
};

export const getSalespersonDashboard = async () => {
  const { data } = await axios.get('/api/my-pages/dashboard/salesperson');
  return data;
};

export const getAdminDashboard = async () => {
  const { data } = await axios.get('/api/my-pages/dashboard/admin');
  return data;
};

export const updateUserPassword = async (data) => {
  const response = await axios.post('/api/my-pages/auth/password', { ...data });
  return response;
};

export const deleteUser = async (id) => {
  const response = await axios.delete(`/api/my-pages/user/${id}`);
  return response;
};

export const requestRestoreUserPassword = async (email) => {
  const response = await axios.post('/api/my-pages/auth/restore-password-mail', { email });
  return response;
};

export const restoreUserPassword = async (token, newPassword) => {
  const response = await axios.post(
    '/api/my-pages/auth/restore-password',
    { newPassword },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return response;
};

export const requestUserInvite = async (userId) => {
  const response = await axios.post('/api/my-pages/user/request-user-invite', { userId });
  return response;
};

export const getRequestUserInvite = async (userId) =>
  axios.get(`/api/my-pages/user/request-user-invite/${userId}`);

export const userInviteConfirmation = async (token, newPassword) => {
  const response = await axios.post(
    '/api/my-pages/auth/user-invite-confirmation',
    { newPassword },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return response;
};

export const getTokenAsUser = async (email) => {
  const response = await axios.post('/api/my-pages/auth/get-user-token', { email });
  return response;
};

export const changeCompanyName = async (data) => {
  const response = await axios.post('/api/my-pages/user/company-name', data);
  return response;
};

export const getSalesperson = async () => {
  const response = await axios.get('/api/my-pages/user/salesperson');
  return response;
};

export const getAllCompanyName = async (search) => {
  const decodedSearch = search?.replaceAll('&', '%26');
  try {
    const { data } = await axios.get(
      `/api/my-pages/user/all-company${search ? `?search=${decodedSearch}` : ''}`,
    );
    return data;
  } catch (error) {
    return console.log(error);
  }
};

export const changeClientSalesperson = async (userId, params) => {
  let query = '';
  if (params.salespersonId) {
    query = `&salespersonId=${params.salespersonId}`;
  }
  if (params.email) {
    query = `&email=${params.email}`;
  }
  try {
    const response = await axios.put(`/api/my-pages/user/salesperson?userId=${userId}${query}`);
    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const postUserFile = async (data) => {
  try {
    const response = await axios.post('/api/my-pages/user-file', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const getMyFiles = async (page = 0, limit = 20) => {
  try {
    const response = await axios.get(`/api/my-pages/user-file?page=${page}&limit=${limit}`);
    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const deleteMyFile = async (link) => {
  try {
    const response = await axios.delete(`/api/my-pages/user-file?link=${link}`);
    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const sendFormData = async (data) => {
  try {
    const response = await axios.post('/api/my-pages/user-file/info', data);
    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const getFormData = async () => {
  try {
    const response = await axios.get('/api/my-pages/user-file/my-info');
    return response;
  } catch (error) {
    return console.log(error);
  }
};
