import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LinkToInviteUserModal from './adminClientList/LinkToInviteUserModal';
import RequestUserInvite from './adminClientList/RequestUserInvite';
import useAuth from '../hooks/useAuth';

const ClientCard = ({
  client,
  loadingCell,
  clientIconClickHandler,
  successInviteList,
  setLoadingCell,
  setSuccessInviteList,
  userIndex,
  setUsersList,
  getSalespersonNamesString,
}) => {
  const navigate = useNavigate();
  const { userMe } = useAuth();
  return (
    <Card variant="outlined">
      <CardContent sx={{ position: 'relative' }}>
        <Typography sx={{ fontWeight: 700 }}>{client.companyName || ''}</Typography>
        {client.agreementStartdate && (
          <Typography>
            <span style={{ fontWeight: 500 }}>Live date : </span>
            {client.agreementStartdate?.slice(0, 10) || ''}
          </Typography>
        )}
        {client.noticeDate && (
          <Typography>
            <span style={{ fontWeight: 500 }}>Termination Date : </span>
            {client.noticeDate?.slice(0, 10) || ''}
          </Typography>
        )}
        {client.agreementEnddate && (
          <Typography>
            <span style={{ fontWeight: 500 }}>End date : </span>
            {client.agreementEnddate?.slice(0, 10) || ''}
          </Typography>
        )}
        {client.revenue && (
          <Typography>
            <span style={{ fontWeight: 500 }}>Revenue : </span>
            {client.revenue?.toFixed(0) || ''}
          </Typography>
        )}
        <Typography>
          <span style={{ fontWeight: 500 }}>Total leads : </span>
          {client.countLeads || 0}
        </Typography>
        <Typography>
          <span style={{ fontWeight: 500 }}>Leads 30 dagar : </span>
          {client.countLeadsLast30Days}
        </Typography>
        <Typography>
          <span style={{ fontWeight: 500 }}>Antal sidor : </span>
          {client.countSites || 0}
        </Typography>
        {getSalespersonNamesString(client.sites) && (
          <Typography>
            <span style={{ fontWeight: 500 }}>Säljare : </span>
            {getSalespersonNamesString(client.sites)}
          </Typography>
        )}
        <Typography style={{ fontWeight: 500 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            Actions :
            {client.role > 1 && (
              <Tooltip title="Kundvy" arrow>
                <IconButton size="small" onClick={() => clientIconClickHandler(client.email)}>
                  <PersonIcon
                    sx={{
                      cursor: 'pointer',
                      fill: client.isInvited ? '#00B8B4' : '#646363',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <LinkToInviteUserModal clientId={client.id} />
            {!client.isInvited && loadingCell !== client.id && (
              <RequestUserInvite
                isDisabled={successInviteList.includes(client.id)}
                clientId={client.id}
                setLoadingCell={setLoadingCell}
                setUsersList={setUsersList}
                setSuccessInviteList={setSuccessInviteList}
                isActive={client.isInviteSent}
                userIndex={userIndex}
              />
            )}
            <Tooltip title="Inställningar" arrow>
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`/${userMe}/client/${client.id}`);
                }}>
                <Settings sx={{ cursor: 'pointer', fill: '#646363' }} />
              </IconButton>
            </Tooltip>
            {loadingCell === client.id && (
              <Box>
                <CircularProgress sx={{ height: '22px !important', width: '22px !important' }} />
              </Box>
            )}
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ClientCard;
