import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { getUsersCompanyList } from '../API/companyApi';
import { getSalesperson } from '../API/usersApi';

const useClientCompanyList = () => {
  const [clientCompaniesList, setClientCompaniesList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState({ type: 'off', from: null, to: null });
  const [currentSalespersonId, setCurrentSalespersonId] = useState(0);
  const [salespersons, setSalespersons] = useState();
  const [expandedId, setExpandedId] = useState([]);
  const [type, setType] = useState('active');
  const [loadingCell, setLoadingCell] = useState(null);
  const [sort, setSort] = useState('');
  const [reverseSort, setReversSort] = useState(false);

  const isDesktop = useMediaQuery('(min-width:600px)');

  const changeSort = (currentSort) => {
    if (currentSort === sort) {
      setReversSort(!reverseSort);
    } else {
      setSort(currentSort);
      setReversSort(false);
    }
    setPage(0);
  };

  const changeDateFilter = (date) => {
    if (date.type !== 'off') {
      setSort(date.type);
      setReversSort(true);
    } else {
      setSort('');
    }
    setDateFilter(date);
  };

  const updateUserCompany = async () => {
    setLoadingCell(0);
    const data = await getUsersCompanyList({
      search,
      page,
      limit,
      type,
      salespersonId: currentSalespersonId,
      sort,
      reverseSort,
      dateFilter,
    });
    setCount(data.count);
    setClientCompaniesList(data.companies);
    setLoadingCell(null);
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const typeHandler = (e) => {
    setPage(0);
    setType(e.target.value);
  };

  const getSalespersonNamesString = (salesId) => {
    let salesman = '';
    if (salesId) {
      const findOne = salespersons?.find((sales) => sales.id === salesId);
      if (findOne) {
        salesman = `${findOne.firstName} ${findOne.lastName?.length ? findOne.lastName : ''}`;
      }
    }
    return salesman;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const clickHandler = (id) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  useEffect(() => {
    updateUserCompany();
  }, [search, page, limit, currentSalespersonId, type, sort, reverseSort, dateFilter]);

  useEffect(() => {
    const getAllSalesperson = async () => {
      const { data } = await getSalesperson();
      setSalespersons(data);
    };

    getAllSalesperson();
  }, []);

  return {
    clientCompaniesList,
    search,
    page,
    count,
    limit,
    openDateFilter,
    dateFilter,
    currentSalespersonId,
    expandedId,
    type,
    loadingCell,
    sort,
    reverseSort,
    isDesktop,
    salespersons,
    setCurrentSalespersonId,
    setOpenDateFilter,
    changeSort,
    changeDateFilter,
    searchInputHandler,
    typeHandler,
    getSalespersonNamesString,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
  };
};

export default useClientCompanyList;
