import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { getUsersList } from '../API/usersApi';

const UserStatisticsTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUsersList({
          page: 0,
          limit: 20,
          role: 2,
          countActions: 'true',
          getLastLogin: 'true',
          sort: 'actionsCount',
        });
        setUsers(data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Företag</TableCell>
            <TableCell>Visits last 30 days</TableCell>
            <TableCell>Live date</TableCell>
            <TableCell>Senast besökt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.companyName}</TableCell>
              <TableCell>{user.actionsCount}</TableCell>
              <TableCell>{user.agreementStartdate?.slice(0, 10) || ''}</TableCell>
              <TableCell>{user.lastLogin?.slice(0, 10) || ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserStatisticsTable;
