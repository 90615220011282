import axios from '../utils/axios';

export const getUsersCompanyList = async ({
  search,
  page,
  limit,
  type,
  salespersonId,
  sort,
  reverseSort,
  dateFilter,
}) => {
  const decodedSearch = search?.replaceAll('&', '%26');
  let query = `type=${type}`;
  if (search?.length > 0) {
    query += `&name=${decodedSearch}`;
  }
  if (page !== 0) {
    query += `&offset=${page * limit}`;
  }
  if (limit !== 50) {
    query += `&limit=${limit}`;
  }
  if (salespersonId > 0) {
    query += query.length ? `&salespersonId=${salespersonId}` : `salespersonId=${salespersonId}`;
  }
  if (sort) {
    query += `&sortField=${sort}`;
  }
  if (reverseSort) {
    query += '&sortOrder=DESC';
  }
  if (dateFilter && dateFilter.type && dateFilter.type !== 'off') {
    query += `&dateField=${dateFilter.type}&endDate=${dateFilter.to}`;
    if (dateFilter.from) {
      query += `&startDate=${dateFilter.from}`;
    }
    if (dateFilter.to) {
      query += `&endDate=${dateFilter.to}`;
    }
  }

  const { data } = await axios.get(`/api/my-pages/company/listing?${query}`);
  return data;
};

export const getOptimizationList = async ({ stage, sort, reverseSort, limit, page }) => {
  let query = '';
  if (stage) {
    query += query.length ? `&stage=${stage}` : `?stage=${stage}`;
  }
  if (sort) {
    query += query.length ? `&orderingField=${sort}` : `?orderingField=${sort}`;
    if (reverseSort) {
      query += '&orderingDirection=DESC';
    }
  }
  if (limit !== 20) {
    query += query.length ? `&limit=${limit}` : `?limit=${limit}`;
  }
  if (page !== 0) {
    query += query.length ? `&offset=${page * limit}` : `?offset=${page * limit}`;
  }
  const { data } = await axios.get(`/api/optimization${query}`);
  return data;
};

export const putOptimization = async ({ id, stage, comment = null }) => {
  const body = {};
  if (id) {
    if (stage) {
      body.stage = stage;
    }
    if (comment !== null) {
      body.comment = comment;
    }
    const { data } = await axios.put(`/api/optimization/${id}`, body);
    return data;
  }
  return null;
};

export const getAllSitesCompany = async (companyName) => {
  try {
    const response = await axios.get(`/api/my-pages/company/sites?companyName=${companyName}`);
    return response.data;
  } catch (err) {
    return console.log(err);
  }
};
