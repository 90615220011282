import { useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanyName } from '../../API/usersApi';

const ChooseLeadsCompanyName = ({ changeCurrentCompanyName }) => {
  const [search, setSearch] = useState('');
  const [searchList, setSearchList] = useState([]);
  const isDesktop = useMediaQuery('(min-width:600px)');

  useMemo(async () => {
    const response = await getAllCompanyName();
    setSearchList(response);
  }, []);

  const onSetCurrentCompanyNameHandler = (event, companyName) => {
    changeCurrentCompanyName(companyName);
    setSearch(companyName);
  };

  return (
    <Autocomplete
      options={search?.length > 2 ? searchList : []}
      sx={{ width: isDesktop ? 300 : '100%', margin: '12px' }}
      size="small"
      onChange={onSetCurrentCompanyNameHandler}
      renderInput={(params) => (
        <TextField
          value={search}
          {...params}
          onChange={(e) => setSearch(e.target.value)}
          label="Företag"
        />
      )}
    />
  );
};

export default ChooseLeadsCompanyName;
