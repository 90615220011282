import React from 'react';
import {
  TextField,
  TablePagination,
  Typography,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import useSiteList from '../../hooks/useSiteList';
import SiteCard from '../SiteCard';
import MapPage from '../UserPage/MapPage/MapPage';
import Loader from '../Loader';

const SitesList = () => {
  const {
    sitesList,
    search,
    page,
    count,
    limit,
    isAvailable,
    isMapView,
    isLoading,
    handleSearchChange,
    handlePageChange,
    handleLimitChange,
    handleViewChange,
    handleAvailableChange,
  } = useSiteList();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          '@media (max-width: 600px)': {
            fontSize: '30px',
          },
        }}
        variant="h1">
        Sidor
      </Typography>
      <div style={{ marginTop: '16px', display: 'flex', gap: '20px' }}>
        <TextField
          id="standard-basic"
          label="Sökord"
          variant="outlined"
          onChange={handleSearchChange}
          value={search}
          size="small"
        />
        <FormControl sx={{ width: '150px' }}>
          {sitesList.length === 1 ? (
            <TextField
              size="small"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              value={sitesList[0].isAvailable ? 'Lediga sidor' : 'Inte Lediga sidor'}
            />
          ) : (
            <>
              <InputLabel id="isAvailable-label">Lediga</InputLabel>
              <Select
                size="small"
                labelId="isAvailable-label"
                value={isAvailable}
                onChange={handleAvailableChange}
                label="Lediga">
                <MenuItem value={2}>All sidor</MenuItem>
                <MenuItem value={1}>Lediga sidor</MenuItem>
                <MenuItem value={0}>Inte Lediga sidor</MenuItem>
              </Select>
            </>
          )}
        </FormControl>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={handleViewChange}>{isMapView ? 'List' : 'Map'} view</Button>
        </div>
      </div>
      <Divider my={6} sx={{ margin: '24px 0' }} />
      {isMapView ? (
        <MapPage data={sitesList} isAllCountryView />
      ) : (
        <Grid container spacing={6}>
          {sitesList?.map((site) => (
            <SiteCard key={site.id} site={site} />
          ))}
        </Grid>
      )}

      {count > limit && !isMapView && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={handleLimitChange}
          />
        </div>
      )}
    </>
  );
};

export default SitesList;
