import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Settings from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Button,
  Typography,
  Tooltip,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import useAuth from '../../hooks/useAuth';
import { getTokenAsUser, getUsersList } from '../../API/usersApi';
import CreateNewUser from './CreateUser';
import RequestUserInvite from '../adminClientList/RequestUserInvite';
import LinkToInviteUserModal from '../adminClientList/LinkToInviteUserModal';
import ClientCard from '../ClientCard';
import { getAxcelFile } from '../../API/fileApi';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;

const AdminClientList = () => {
  const { userMe } = useAuth();
  const [usersList, setUsersList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState({ all: null, active: null, notActive: null });
  const [limit, setLimit] = useState(20);
  const [isInvited, setIsInvited] = useState(0);
  const [openCreateUser, setOpenCreateUser] = useState(false);

  const [loadingCell, setLoadingCell] = useState(null);
  const [successInviteList, setSuccessInviteList] = useState([]);
  const { signInAsUser } = useAuth();

  const [sort, setSort] = useState('');
  const [reverseSort, setReversSort] = useState(false);

  const isDesktop = useMediaQuery('(min-width:600px)');

  const navigate = useNavigate();

  const changeSort = (currentSort) => {
    if (currentSort === sort) {
      setReversSort(!reverseSort);
    } else {
      setSort(currentSort);
      setReversSort(false);
    }
    setPage(0);
  };

  const updateUser = async () => {
    setLoadingCell(0);
    const data = await getUsersList({
      search,
      page,
      limit,
      role: 2,
      isInvited,
      statisticData: true,
      sort,
      countActions: true,
      reverseSort,
      getCompanyLeadsExpectation: 'true',
    });
    setCount((prev) => ({ ...prev, all: data.count }));
    setUsersList(data.users);
    setLoadingCell(null);
  };

  useEffect(() => {
    updateUser();
  }, [search, page, limit, isInvited, sort, reverseSort]);

  const isInvitedHandler = (e) => {
    setPage(0);
    setIsInvited(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onCloseModal = async () => {
    setOpenCreateUser(false);
    await updateUser();
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const clientIconClickHandler = async (email) => {
    try {
      const {
        data: { token },
      } = await getTokenAsUser(email);
      signInAsUser(token);
      window.location.href = '/';
    } catch (e) {
      console.log(e.message);
    }
  };

  const getExcelFile = async () => {
    const response = await getAxcelFile();
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers['content-type'] }),
      );
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Users.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      console.error('Failed to download file');
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          flexWrap: 'wrap',
        }}>
        <Typography
          sx={{
            width: '100%',
            fontWeight: 500,
            '@media (max-width: 600px)': {
              fontSize: '30px',
            },
          }}
          variant="h2">
          Kunder
          <Button style={{ float: 'right' }} onClick={getExcelFile}>
            Download in Excel
          </Button>
        </Typography>
      </div>

      <Card
        mb={6}
        sx={{
          maxWidth: '100%',
          '@media (min-width: 960px)': {
            maxWidth: 'calc(100vw - 205px)',
          },
          '@media (min-width: 1280px)': {
            maxWidth: 'calc(100vw - 242px)',
          },
          border: '1px solid',
          borderColor: 'grey.300',
        }}>
        <div
          style={{
            margin: '16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box sx={{ display: 'flex', gap: '16px 0', flexWrap: 'wrap' }}>
            <TextField
              sx={{ marginBottom: 0, marginRight: '15px' }}
              id="standard-basic"
              label="Sök företag"
              variant="outlined"
              onChange={searchInputHandler}
              value={search}
              size="small"
            />
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <InputLabel id="isInvited-label">Loggat</InputLabel>
              <Select
                size="small"
                labelId="isInvited-label"
                value={isInvited}
                onChange={isInvitedHandler}
                label="Loggat">
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Kund loggat in</MenuItem>
                <MenuItem value={2}>Kund inte loggat in</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {userMe === 'admin' && (
            <Button
              sx={{
                marginRight: '8px',
                padding: '6px 10px',
                whiteSpace: 'nowrap',
                flexShrink: 0,
                maxHeight: '36px',
              }}
              variant="contained"
              onClick={() => setOpenCreateUser(!openCreateUser)}>
              Skapa ny kund
            </Button>
          )}
        </div>
        <Divider my={6} sx={{ margin: '12px 0 0' }} />
        {openCreateUser && <CreateNewUser onClose={onCloseModal} kund />}
        {loadingCell === 0 && (
          <Box sx={{ width: '22px', height: '50px', margin: '50px auto 0' }}>
            <CircularProgress sx={{ width: '100%', height: '100%' }} />
          </Box>
        )}
        {isDesktop && loadingCell === null && (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Företag</TableCell>
                  <TableCell
                    onClick={() => changeSort('actionsCount')}
                    sx={{ whiteSpace: 'nowrap', cursor: 'pointer', fontWeight: 400 }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      Besök 30 dagar
                      {sort === 'actionsCount' && (
                        <ArrowDropDownIcon
                          style={{
                            transform: reverseSort ? 'rotate(180deg)' : '',
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => changeSort('isNewEmailFlow')}
                    sx={{ whiteSpace: 'nowrap', cursor: 'pointer', fontWeight: 400 }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      Email Flow
                      {sort === 'isNewEmailFlow' && (
                        <ArrowDropDownIcon
                          style={{
                            transform: reverseSort ? 'rotate(180deg)' : '',
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '250px', fontWeight: 400 }} align="right">
                    <span style={{ paddingRight: '8px' }}>Actions</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList?.map((row, userIndex) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.email || ''}</TableCell>
                    <TableCell>{row.companyName || ''}</TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>{row.actionsCount}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                      {row.isNewEmailFlow ? 'New' : 'Old'}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>
                      <div
                        style={{
                          justifyContent: 'end',
                          display: 'flex',
                        }}>
                        {row.role > 1 && (
                          <Tooltip title="Kundvy" arrow>
                            <IconButton
                              size="small"
                              onClick={() => clientIconClickHandler(row.email)}>
                              <PersonIcon
                                sx={{
                                  cursor: 'pointer',
                                  fill: row.isInvited ? '#00B8B4' : '#646363',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}

                        <LinkToInviteUserModal clientId={row.id} />

                        {!row.isInvited && loadingCell !== row.id && (
                          <RequestUserInvite
                            isDisabled={successInviteList.includes(row.id)}
                            clientId={row.id}
                            setLoadingCell={setLoadingCell}
                            setUsersList={setUsersList}
                            setSuccessInviteList={setSuccessInviteList}
                            isActive={row.isInviteSent}
                            userIndex={userIndex}
                          />
                        )}

                        <Tooltip title="Inställningar" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              navigate(`/${userMe}/client/${row.id}`);
                            }}>
                            <Settings sx={{ cursor: 'pointer', fill: '#646363' }} />
                          </IconButton>
                        </Tooltip>

                        {loadingCell === row.id && (
                          <Box>
                            <CircularProgress
                              sx={{ height: '22px !important', width: '22px !important' }}
                            />
                          </Box>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {count.all > limit && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={count.all}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeLimit}
                />
              </div>
            )}
          </TableWrapper>
        )}
      </Card>
      {!isDesktop && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {usersList?.map((row, userIndex) => (
            <ClientCard
              client={row}
              loadingCell={loadingCell}
              clientIconClickHandler={clientIconClickHandler}
              successInviteList={successInviteList}
              setLoadingCell={setLoadingCell}
              setSuccessInviteList={setSuccessInviteList}
              userIndex={userIndex}
              setUsersList={setUsersList}
            />
          ))}
          {count.all > limit && (
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={count.all}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AdminClientList;
