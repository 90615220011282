import dayjs from 'dayjs';

export const getLastSevenDaysRange = () => {
  const range = {};
  const today = dayjs();
  range.from = today.subtract(7, 'day').format('YYYY-MM-DD');
  range.to = today.format('YYYY-MM-DD');

  return range;
};

export const getLastThirtyDaysRange = () => {
  const range = {};
  const today = dayjs();
  range.from = today.subtract(30, 'day').format('YYYY-MM-DD');
  range.to = today.format('YYYY-MM-DD');

  return range;
};

export const dateNow = () => dayjs().format('YYYY-MM-DD HH:mm:ss');
