export const getSubjectEmail = (subject) => {
  if (subject.source === '1') {
    const regex = /<([^>]+)>/;
    const match = subject.from.match(regex);
    return match ? match[1] : subject.from;
  }
  if (subject.source === '3') {
    if (typeof subject.data === 'string') {
      const regex = /<strong>E-post: <\/strong>(.*?)</;
      const match = subject.data.match(regex);
      if (!match) {
        const secondRegex = /<strong>Mejl: <\/strong>(.*?)</;
        const result = subject.data.match(secondRegex);
        return result ? result[1] : '';
      }
      return match[1];
    }
    return subject.data['E-post'] || subject.data.Mejl;
  }
  return '';
};
